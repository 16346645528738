import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import Logo from "../images/red-design.svg"
import SEO from "../components/seo"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    
    <div style={{ maxWidth: `400px`, marginBottom: `1.45rem`, marginLeft:`auto`, marginRight:`auto`, }}>
      <Link to="/">
        <img src ={Logo} alt ="Red Design Lab Logo"/>
      </Link>
    </div>
  
  </Layout>
)

export default IndexPage
